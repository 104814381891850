<template>
  <div>
    <span v-if="hasLabel && data.properties.filed_content != 'Hide' && data.input_type != 'ENTITY_VARIABLE'">{{label||data.label}}</span>
      <span v-if="data.validations.required && hasLabel" style="color:red;font-weight:bold;">
          *
        </span>
    <el-popover
      v-if="data.description"
      placement="top-start"
      trigger="hover"
      :content="data.description"
    >
      <i class="el-icon-info" slot="reference"></i>
    </el-popover>
    <el-upload
      v-if="data.validations.multiple && data.input_type != 'ENTITY_VARIABLE'&&  data.properties.filed_content != 'Hide'"
      :style="getStyle"
      :on-change="upload"
      :accept="stringifiedArray"
      class="avatar-uploader"
      :show-file-list="false"
      multiple
      :disabled="checkReadonly()"
      :class="{ 'disabled-div': checkReadonly() }"
      @change="applyFormRules"
      :auto-upload="false"
      action
    >
      <img v-if="(!this.fileName || !this.fileName.length) && !this.defaulFileName" :src="logoUploadUrl+'/assets/img/icons/upload-icon.svg'" alt="icon" />
    <span v-else>
          {{ this.defaulFileName ? this.defaulFileName : this.fileName }} 
          <el-tag
          style="cursor: pointer; margin-top: -14px"
          class="ml-1"
          :value="this.files.length"
          v-if="this.files.length > 1"
          @click.stop="downloadDocumentsPopup()"
        > +{{ this.files.length - 1 }}
        </el-tag>
          <i class="el-icon-upload2"></i>
        </span>
    </el-upload>
   <el-upload
     v-else-if="!data.validations.multiple && data.input_type != 'ENTITY_VARIABLE'&&  data.properties.filed_content != 'Hide'"
      :style="getStyle"
      :on-change="upload"
      :accept="stringifiedArray"
      class="avatar-uploader"
      :show-file-list="false"
      :disabled="checkReadonly()"
      :class="{ 'disabled-div': checkReadonly() }"
      @change="applyFormRules"
      :auto-upload="false"
      action
    >
     <img v-if="!form[data.key] " :src="logoUploadUrl+'/assets/img/icons/upload-icon.svg'" alt="icon" />
    <span v-else>
      
          {{ this.defaulFileName ? this.defaulFileName : this.fileName }}
          <i class="el-icon-upload2"></i>
        </span>
    </el-upload>
    <el-dialog
    :modal="false"
      :visible.sync="downloadDocuments"
      title="Download Documents"
       :close-on-click-modal="false"
        :close-on-press-escape="false"
        :before-close="closeDownloadDocuments"
    >
        <el-row>
        <el-input
          class="search mb-1"
          placeholder="Search"
          v-model="searchDocument"
          clearable
          @input="searchDocumentName"
          prefix-icon="el-icon-search"
        ></el-input>
      </el-row>
      <div class="sidemenu">
        <el-table
          border
          :data="documentSearchData"
          :header-row-class-name="'my-header-row'"
          :header-cell-style="headerCellStyle"
          class="tableSty"
          :show-header="true"
        >
          <el-table-column class="labelSty" label="Document Name">
            <template slot-scope="scope">
              <p style="font-size: 14px" slot="reference">
                {{ scope.row.name }}
              </p>
            </template>
          </el-table-column>
          <el-table-column label="Action" width="150">
            <template slot-scope="scope">
              <el-button
                @click="onDelete(scope.row)"
                target="_blank"
                ref="downloadLink"
                style="width: 120px; font-size: 12px"
              >
                Delete
                <i class="el-icon-delete"></i>
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <span slot="footer">
        <el-button @click="downloadDocuments = false"> Close </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import userPermissionsHelper from "@/mixins/userPermissionsHelper";
import { mapGetters } from "vuex";

export default {
  name:"templates-formComponentsExecute-FileExecuteView",
  mixins: [userPermissionsHelper],
  components: {},
  props: ["data", "form", "isView", "hasLabel", "checkIsDisabled",],
  computed: {
    stringifiedArray() {
      return this.data.validations.allowed_file_types.join(',');
    },
    logoUploadUrl() {
      return process.env.VUE_APP_S3_BUCKET_URL;
    },
    getStyle() {
      return `height:${this.data.height - 20}px`;
    },
    ...mapGetters("templatesData", ["getDownloadUrl"])
  },
  mounted() {
    this.defaulFileName = this.data && this.data.files && this.data.files[0] && this.data.files[0].name ? this.data.files[0].name : this.fileName
    this.documentSearchData =this.files
    if(this.form && this.form[this.data.key]){
      this.form[this.data.key].file = null;
    }
   
  },
  data() {
    return {
      validations: [],
      fileName:"",
      defaulFileName:"",
      files: [],
      downloadDocuments: false,
      documentSearchData : [],
      searchDocument : '',
    };
  },
  methods: {
    applyFormRules() {
      this.$emit("applyFormRules");
    },
    checkReadonly() {
      if (this.checkIsDisabled) {
        return true;
      }
      //If it's an entity varaible, have to check access 
      if(this.data.field_assignable === 'read_only' && this.data.is_entity_variable){
        return true
      }
      return !this.fieldFilledByCurrentUser(this.data.filled_by);
    },
   async upload(file) {
      this.fileName = file && file.name
      await this.files.push(file);
      this.$set(this.form, this.data.key, {
        type: "DOCUMENT",
        name: file.name,
        file: file,
        new: true,
        file_name: file.name,
        file_type: file.raw.type,
        file_size_in_kb: file.size / 1024,
        files: this.files,
      });
    },
    searchDocumentName(){
      this.documentSearchData = []
      this.documentSearchData = this.files.filter(e=>e.name.toLowerCase().includes(this.searchDocument.toLowerCase()) == true)
    },
    downloadDocumentsPopup(){
      this.downloadDocuments = true
    },
    closeDownloadDocuments(){
      this.downloadDocuments = false
    },
    headerCellStyle() {
      return {
        backgroundColor: "#F2F6FC",
        color: "#606266",
        fontWeight: "bold",
      };
    },
    onDelete(row){
      let index = this.files.findIndex(obj => obj.uid === row.uid);
      this.files.splice(index,1);
    },
  }
};
</script>

<style lang="scss" scoped>
.disabled-div {
    pointer-events: none;
    opacity: 0.5; 
    cursor: not-allowed;
  }
.avatar-uploader {
  width: inherit !important;
  justify-content: center !important;
}
.avatar {
  height: inherit !important;
  justify-content: center !important;
}
.attachment_img {
  height: inherit !important;
}
</style>